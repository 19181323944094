<app-action-btn
  [IconType]="'more'"
  [active]="false"
  cdkOverlayOrigin
  (custom_click)="onMore($event)"
  #actionsMenu="cdkOverlayOrigin"></app-action-btn>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="actionsMenu"
  [cdkConnectedOverlayOffsetY]="6"
  [cdkConnectedOverlayPositions]="[
    { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
  ]"
  [cdkConnectedOverlayOpen]="isOpen">
  <div class="bottom-margin" cdkMenu>
    <div class="custom-dropdown radius-4 radius-bottom-left">
      <ng-container *ngFor="let action of actions">
        <dropdown-single-piece
          class="w-100 cursor-pointer"
          [label]="action.label"
          [value]="action.key"
          [color]="action.color || ''"
          [type]="'bold'"
          [icon]="true"
          [iconType]="action.icon || ''"
          [dragDrop]="false"
          [toggle]="false"
          [more]="false"
          [radio]="false"
          (onAction)="onActionClick($event)"></dropdown-single-piece>
      </ng-container>
    </div>
  </div>
</ng-template>
