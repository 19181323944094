<div
  class="thumbnail-container"
  [class.selected]="entry.selected"
  *ngIf="entry"
  (click)="onClick($event)">
  <div class="thumbnail">
    <img
      [alt]="entry.title || entry.file_name || ''"
      [ngSrc]="entry.url.image_thumbnail || ''"
      fill />
  </div>
  <h4 class="title h4-reg">{{ entry.title || entry.file_name || '' | truncate: [20] }}</h4>
  <app-checkbox
    class="selection"
    [checked]="entry.selected || false"
    (onCheckChange)="onEntryCheck()"></app-checkbox>
</div>
