<h1 class="h1-headers">
  {{ mode === 'rename' ? 'Rename folder' : 'Create new folder' }}
</h1>
<div [formGroup]="form">
  <app-form-field>
    <app-inputs
      formControlName="name"
      Label="Name"
      placeholder="i.e. Black Friday Campaign 2024"></app-inputs>
  </app-form-field>
  <app-form-field *ngIf="mode !== 'rename'">
    <app-media-folder-tree-select
      formControlName="parent_folder_id"
      placeholder="Select parent folder"></app-media-folder-tree-select>
  </app-form-field>
  <app-form-field>
    <app-buttons
      IconType=""
      [shortcutKey]="false"
      [disabled]="!form.valid"
      size="large"
      label="Save"
      (btnClick)="onSave()"></app-buttons>
  </app-form-field>
  <app-form-field>
    <app-secondary-btns
      size="large"
      label="Cancel"
      (onClick)="onCancelClick()"></app-secondary-btns>
  </app-form-field>
</div>
