<app-media-gallery-entries
  [style.display]="view === MediaGalleryView.List ? 'flex' : 'none'"
  (onClose)="onClose.emit()"></app-media-gallery-entries>

<app-media-gallery-entry
  [style.display]="view === MediaGalleryView.Entry ? 'flex' : 'none'"
  [editable]="true"
  [entry]="selectedEntry$ | async"
  (onDelete)="onEntryDelete()"
  (onClose)="onEntryClose()"></app-media-gallery-entry>

<div [style.display]="view === MediaGalleryView.Upload ? 'block' : 'none'">
  <div class="gallery-upload">
    <div class="gallery-upload-header">
      <app-link-btn
        [IconType]="'chevron_left'"
        label="Back"
        (custom_click)="onUploadBack()"></app-link-btn>
    </div>
    <div>
      <app-media-uploader
        (onImagesUpload)="handleImagesUpload($event)"
        (onVideosUpload)="handleVideosUpload($event)"></app-media-uploader>
    </div>
  </div>
</div>
