<h1 class="h1-headers">Move files</h1>
<div [formGroup]="form">
  <app-form-field>
    <app-media-folder-tree-select
      formControlName="folder_id"
      placeholder="Select folder"></app-media-folder-tree-select>
  </app-form-field>
  <app-form-field>
    <app-buttons
      size="large"
      label="Move"
      [disabled]="!form.valid"
      (btnClick)="onSave()"></app-buttons>
  </app-form-field>
  <app-form-field>
    <app-secondary-btns size="large" label="Cancel" (onClick)="onCancel()"></app-secondary-btns>
  </app-form-field>
</div>
