import { Component } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormFieldComponent } from '@enginuity/campaign/molecules/form-field/form-field.component';
import { ButtonsComponent } from '@enginuity/core/molecules/buttons/buttons.component';
import { SecondaryBtnsComponent } from '@enginuity/core/molecules/secondary-btns/secondary-btns.component';
import { ModalService } from '@services/core-services/modal.service';

@Component({
  selector: 'app-media-entry-move-form',
  imports: [ReactiveFormsModule, FormFieldComponent, ButtonsComponent, SecondaryBtnsComponent],
  templateUrl: './media-entry-move-form.component.html',
  styleUrl: './media-entry-move-form.component.scss',
})
export class MediaEntryMoveFormComponent {
  constructor(
    private readonly fb: FormBuilder,
    private readonly modal: ModalService
  ) {}

  protected form = this.fb.group({
    folder_id: ['', Validators.required],
  });

  onSave() {
    this.modal.close(this.form.getRawValue());
  }

  onCancel() {
    this.modal.dismissAll();
  }
}
