import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MediaImage, MediaVideo } from '@services/media-services/models';
import { NgIf, NgOptimizedImage } from '@angular/common';
import { TruncatePipe } from '@enginuity/core/atoms/pipes/truncate.pipe';
import { CheckboxComponent } from '@enginuity/core/molecules/checkbox/checkbox.component';

@Component({
  selector: 'app-media-item-thumbnail',
  imports: [NgOptimizedImage, TruncatePipe, CheckboxComponent, NgIf],
  templateUrl: './media-item-thumbnail.component.html',
  styleUrl: './media-item-thumbnail.component.scss',
})
export class MediaItemThumbnailComponent {
  @Input() entry: MediaImage | MediaVideo | undefined;

  @Output() onSelect = new EventEmitter<MediaImage | MediaVideo>();
  @Output() onCheck = new EventEmitter<MediaImage | MediaVideo>();
  @Output() onUncheck = new EventEmitter<MediaImage | MediaVideo>();

  onClick($event: MouseEvent) {
    $event.stopPropagation();
    this.onSelect.emit(this.entry);
  }

  onEntryCheck() {
    if (this.entry) {
      this.entry.selected = !this.entry.selected;
      if (this.entry.selected) {
        this.onCheck.emit(this.entry);
      } else {
        this.onUncheck.emit(this.entry);
      }
    }
  }
}
