import { Component, EventEmitter, Output } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { ActionBtnComponent } from '@enginuity/core/molecules/action-btn/action-btn.component';
import { NgForOf } from '@angular/common';
import { Action } from '@services/core-services/models';
import { DropdownSinglePieceComponent } from '@enginuity/core/molecules/dropdown-single-piece/dropdown-single-piece.component';
import { ActionEvent } from '@services/ui-services/models/action-event';

@Component({
  selector: 'app-media-folder-actions',
  templateUrl: './media-folder-actions.component.html',
  styleUrl: './media-folder-actions.component.scss',
  imports: [OverlayModule, ActionBtnComponent, NgForOf, DropdownSinglePieceComponent],
})
export class MediaFolderActionsComponent {
  @Output() onAction = new EventEmitter<ActionEvent>();

  protected isOpen: boolean = false;
  protected actions: Action[] = [
    { key: 'rename', label: 'Rename', icon: 'underline', color: 'default' },
    { key: 'delete', label: 'Delete folder', icon: 'delete', color: 'red' },
  ];

  onMore($event: MouseEvent) {
    $event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  onActionClick($event: ActionEvent) {
    $event.stopPropagation();
    this.isOpen = false;
    this.onAction.emit($event);
  }
}
