import { Injectable } from '@angular/core';
import {
  FilterBaseProvider,
  FilterContext,
  FilterOptionsService,
} from 'src/base/services/core-services/filters';
import { UserSettingsService } from '@services/core-services/user-settings.service';

const MODULE = 'media-files';
const PREFIX = 'filters';
const TOKEN = `${MODULE}:${PREFIX}`;

const DEFAULT_FILTERS = [] as FilterContext[];

@Injectable()
export class MediaFilterProvider extends FilterBaseProvider {
  override defaultFilters = DEFAULT_FILTERS;

  override MODULE = MODULE;

  constructor(
    protected override userSettings: UserSettingsService,
    protected override filterOptionsService: FilterOptionsService
  ) {
    super(TOKEN, userSettings, filterOptionsService);
  }
}
