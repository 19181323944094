<div class="folders-header">
  <h3 class="h3-bold primary-100">Folders</h3>

  <app-badges
    [withIcon]="false"
    Type="tag"
    IconType="add"
    [label]="(folders?.length || 0).toString()"
    [ellipsis]="true"></app-badges>

  <div class="spacing"></div>

  <app-action-btn
    [IconType]="'edit'"
    [active]="mode === FolderViewMode.Edit"
    (custom_click)="onEdit()"></app-action-btn>

  <app-action-btn [IconType]="'add'" [active]="false" (custom_click)="onAdd()"></app-action-btn>
</div>

<div class="border-line"></div>

<cdk-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <!-- This is the tree node template for leaf nodes -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node"
    (click)="selectFolder($event, node)"
    class="folder-tree-node-container"
    [class.selected]="selection?.id === node.id">
    <!-- use a disabled button to provide padding for tree leaf -->
    <div class="folders d-flex align-items-center spacing-4">
      <dropdown-single-piece
        class="w-100 cursor-pointer"
        [label]="node.name"
        [color]="'default'"
        [type]="'bold'"
        [icon]="true"
        [iconType]="'open_folder'"
        [dragDrop]="false"
        [toggle]="false"
        [more]="false"
        [radio]="false"></dropdown-single-piece>
      <div class="spacing"></div>
      <app-media-folder-actions
        *ngIf="mode === FolderViewMode.Edit"
        (onAction)="onFolderAction($event, node)"></app-media-folder-actions>
    </div>
  </cdk-nested-tree-node>
  <!-- This is the tree node template for expandable nodes -->
  <cdk-nested-tree-node
    *cdkTreeNodeDef="let node; when: hasChild"
    (click)="selectFolder($event, node)"
    class="folder-tree-node-container"
    [class.selected]="selection?.id === node.id">
    <div class="folders d-flex align-items-center spacing-4">
      <dropdown-single-piece
        class="w-100 cursor-pointer"
        [label]="node.name"
        [color]="'default'"
        [type]="'bold'"
        [icon]="true"
        [iconType]="treeControl.isExpanded(node) ? 'open_folder' : 'close_folder'"
        [dragDrop]="false"
        [toggle]="false"
        [more]="false"
        [radio]="false"
        cdkTreeNodeToggle></dropdown-single-piece>
      <div class="spacing"></div>
      <app-media-folder-actions
        *ngIf="mode === FolderViewMode.Edit"
        (onAction)="onFolderAction($event, node)"></app-media-folder-actions>
    </div>
    <div [class.folder-tree-invisible]="!treeControl.isExpanded(node)">
      <ng-container cdkTreeNodeOutlet></ng-container>
    </div>
  </cdk-nested-tree-node>
</cdk-tree>
